import * as React from "react";
import { Route } from "react-router-dom";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { Routes } from "../../app/router/Routes";
import { SchoolSite } from "../sites/SchoolSite";

export const SchoolRouter = () => (
    <CustomSwitch>
        <Route exact path={Routes.SCHOOL.ROOT}>
            <SchoolSite />
        </Route>
    </CustomSwitch>
);
