import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { Field, Form, Formik, FormikValues } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { CustomInputField } from "../../ui/CustomInputField";
import { ImageLogo } from "../../util/Images";
import { Colors } from "../../util/Colors";
import { mockImage } from "../../util/mockData";
import { API } from "../../../network/API";
import { Routes } from "../../app/router/Routes";

type ILoginValues = {
    email: string;
    password: string;
};

const SiteContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 0%",
    padding: 24,
});

const LoginContainer = styled("div")({
    background: "#fff",
    borderRadius: 4,
    width: "100%",
    maxWidth: 320,
    marginTop: 40,
});

const LoginTitle = styled("div")({
    background: Colors.PRIMARY,
    color: "#fff",
    textTransform: "uppercase",
    padding: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    fontWeight: "bold",
});

export const AuthLoginSite = observer(() => {
    const [error, setError] = React.useState<string>();

    const handleSubmit = async (values: FormikValues) => {
        generalStore.setIsLoading(true);
        setError("");

        try {
            await authStore.loginWithPasswordTeacher(values.firstName, values.lastName, values.password);
            if (authStore.error) {
                if (authStore.error === "PasswordWrong") {
                    setError(t("screen.login.invalid_password_or_email"));
                } else if (authStore.error === "Unknown") {
                    setError(t("screen.login.error_during_login"));
                }
            } else {
                const userInfo = await API.getUserInfo();
                authStore.userInfo = userInfo;

                if (authStore.credentials?.roleId === 1) {
                    pushRoute(Routes.SUPERADMIN.ROOT);
                } else {
                    pushRoute(Routes.SCHOOL.ROOT);
                }
            }
        } catch (error) {
            setError(t("screen.login.error_during_login"));
        }

        generalStore.setIsLoading(false);
    };

    if (!authStore.isRehydrated) {
        return null;
    }

    return (
        <SiteContainer>
            <ImageLogo style={{ maxWidth: 200 }} />
            <LoginContainer>
                <LoginTitle>{t("screen.login.title")}</LoginTitle>
                <div style={{ padding: 24, border: `1px solid ${Colors.PRIMARY}`, borderTop: "none" }}>
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            password: "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                            firstName: Yup.string().required(t("screen.login.form.email.validation_error")).trim(),
                            lastName: Yup.string().required(t("screen.login.form.email.validation_error")).trim(),
                            password: Yup.string().required(t("screen.login.form.password.validation_error")),
                        })}
                        validateOnBlur
                    >
                        {({ errors, touched, isSubmitting }) => (
                            <Form>
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.login.form.firstName.label")}
                                    name="firstName"
                                    type="firstName"
                                    required
                                    autoComplete="lastName"
                                    errorMessage={errors.firstName}
                                    isTouched={touched.firstName}
                                />
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.login.form.lastName.label")}
                                    name="lastName"
                                    type="lastName"
                                    required
                                    autoComplete="lastName"
                                    errorMessage={errors.lastName}
                                    isTouched={touched.lastName}
                                />
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.login.form.password.label")}
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    errorMessage={errors.password}
                                    isTouched={touched.password}
                                />
                                {error && <div style={{ color: "#f00", fontSize: 14 }}>{error}</div>}
                                <Button
                                    variant="contained"
                                    style={{
                                        boxShadow: "none",
                                        borderRadius: 24,
                                        marginTop: 24,
                                    }}
                                    fullWidth
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {t("screen.login.form.submit")}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </LoginContainer>
        </SiteContainer>
    );
});
