export const Colors = {
    PRIMARY: "#87AC5D",
    PRIMARY_SHADE_DARK: "#43542e",
    PRIMARY_SHADE_LIGHT: "#c4e3a1",
    SECONDARY: "#2E6171",
    SECONDARY_SHADE_DARK: "#1C3B45",
    SECONDARY_SHADE_LIGHT: "#6AA5B8",
    ERROR: "#ed2f42",
    BORDER_LIGHT: "#e6e6e6",
    BORDER_PRIMARY: "#739150",
};
