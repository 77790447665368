import {
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    styled,
} from "@mui/material";
import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { authStore } from "../../stores/AuthStore";
import LogoutIcon from "@mui/icons-material/Logout";
import { Colors } from "../util/Colors";
import { history } from "../app/router/history";
import { Routes } from "../app/router/Routes";

const Container = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 16,
    height: 60,
    backgroundColor: Colors.PRIMARY_SHADE_LIGHT,
});

const User = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const StyledMenu = styled(Menu)({
    "& .MuiMenu-list": {
        padding: 0,
    },
});

const Avatar = styled("img")({
    height: 40,
    width: 40,
    borderRadius: 100,
    backgroundColor: "white",
});

const handleClickLogout = () => {
    authStore.logout();
    history.push(Routes.ROOT);
};

const userMenu = (
    <MenuList>
        <MenuItem onClick={handleClickLogout}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
        </MenuItem>
    </MenuList>
);

export const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    return (
        <>
            <Container>
                <User>
                    <div>
                        <IconButton onClick={handleClickOpenMenu} style={{ marginRight: 12 }}>
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <StyledMenu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
                            {userMenu}
                        </StyledMenu>
                    </div>
                    <div style={{ marginRight: 12 }}>
                        {authStore.user?.firstName} {authStore.user?.lastName}
                    </div>
                    <Avatar src={authStore.user?.image} />
                </User>
            </Container>
            <Divider style={{ borderColor: Colors.BORDER_LIGHT }} />
        </>
    );
};
