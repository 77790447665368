/* eslint-disable no-throw-literal */

import * as Config from "../config";
import { APIError } from "../errors/APIError";
import { authStore, ICredentials } from "../stores/AuthStore";
import queryString from "query-string";
import {
    GetClassesResponse,
    GetClassPresonse,
    GetSchoolResponse,
    GetSchoolsResponse,
    GetTeacherLoginResponse,
    GetStudentsReponse,
    GetTeachersResponse,
    GetUserInfoResponse,
} from "./APITypes";

export const STATUS_CODE_UNAUTHORIZED = 401;

const getAuthHeaders = () => ({
    Authorization: `Bearer ${authStore.credentials && authStore.credentials.accessToken}`,
});

export const isUnauthorizedError = (error: any) => error.statusCode === STATUS_CODE_UNAUTHORIZED;

const request = async ({
    path,
    method,
    body,
    appendAuthToken,
    errors,
    blob,
    headers = {},
    queryParameters,
}: {
    path: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    body?: any;
    appendAuthToken?: boolean;
    errors?: { [code: string]: string };
    blob?: boolean;
    headers?: {
        [header: string]: string;
    };
    queryParameters?: {
        [param: string]: any;
    };
}) => {
    try {
        const params = queryParameters ? `?${queryString.stringify(queryParameters)}` : "";
        const response = await fetch(`${Config.API_BASE_URL}${path}${params}`, {
            method,
            headers: {
                ...(appendAuthToken ? getAuthHeaders() : {}),
                ...headers,
            },
            ...(body ? { body } : {}),
        });

        if (!response.ok) {
            throw {
                statusCode: response.status,
                statusText: errors ? errors[response.status] : response.statusText,
            };
        }

        return blob ? response.blob() : response.json();
    } catch (error) {
        if (isUnauthorizedError(error)) {
            authStore.logout();
        }
        throw error;
    }
};

export const API = {
    // TODO: REMOVE, just for explanation purposes
    // BEISPIEL API CALL

    // functionName(): Promise<TYPE>, the type for API calls is always a promise, since we need to wait for the a response from the backend.
    // The actual data type is written in the brackets
    addClass(): Promise<any> {
        return request({
            // the path from the backend for the specific call. Excluding the BASE_URL
            path: "/api/v1/addClass",
            // the method of the call
            method: "POST",
            // this is the actual data we want to send. It should always be a JSON strinified object
            body: JSON.stringify({
                id: 0,
                petId: 0,
                quantity: 0,
                shipDate: "2022-01-21T09:29:19.847Z",
                status: "placed",
                complete: true,
            }),
            // use this for all calls that should be validated. Login & Registration calls dont need this obbiously
            appendAuthToken: true,
        });
    },

    async loginWithPasswordTeacher(params: {
        firstName: string;
        lastName: string;
        password: string;
    }): Promise<GetTeacherLoginResponse> {
        return await request({
            path: "/api/login/teacher",
            method: "POST",
            body: JSON.stringify({
                firstName: params.firstName,
                surName: params.lastName,
                password: params.password,
            }),
            headers: { "Content-Type": "application/json" },
        });
    },

    async getUserInfo(): Promise<GetUserInfoResponse> {
        return await request({
            path: "/api/login/userinfo",
            method: "GET",
            appendAuthToken: true,
        });
    },

    async getSchools(): Promise<GetSchoolsResponse> {
        return await request({
            path: "/api/schools",
            method: "GET",
            appendAuthToken: true,
        });
    },

    async getSchool(params: { schoolId: string }): Promise<GetSchoolResponse> {
        return await request({
            path: "/api/school",
            method: "GET",
            appendAuthToken: true,
            queryParameters: {
                schoolId: params.schoolId,
            },
        });
    },

    async deleteSchool(params: { schoolId: string }): Promise<boolean> {
        return await request({
            path: "/api/school",
            method: "DELETE",
            appendAuthToken: true,
            queryParameters: {
                schoolId: params.schoolId,
            },
        });
    },

    async getTeachers(params: { schoolId: string }): Promise<GetTeachersResponse> {
        return await request({
            path: "/api/school/teachers",
            method: "GET",
            appendAuthToken: true,
            queryParameters: {
                schoolId: params.schoolId,
            },
        });
    },

    async getStudents(params: { schoolId: string; classId: number; year: number }): Promise<GetStudentsReponse> {
        return await request({
            path: "/api/school/students",
            method: "GET",
            appendAuthToken: true,
            queryParameters: {
                schoolId: params.schoolId,
                classId: params.classId,
                year: params.year,
            },
        });
    },

    async getClasses(params: { schoolId: string }): Promise<GetClassesResponse> {
        return await request({
            path: "/api/school/classes",
            method: "GET",
            appendAuthToken: true,
            queryParameters: {
                schoolId: params.schoolId,
            },
        });
    },

    async getClass(params: { schoolId: string; classId: number; year: number }): Promise<GetClassPresonse> {
        return await request({
            path: "/api/school/class",
            method: "GET",
            appendAuthToken: true,
            queryParameters: {
                schoolId: params.schoolId,
                classId: params.classId,
                year: params.year,
            },
        });
    },
};
