import { makeAutoObservable } from "mobx";
import { IError } from "../components/util/types";
import { ILocales } from "../i18n/ILocales";
import { DEFAULT_LOCALE } from "../i18n/util";

class GeneralStore {
    locale: ILocales = DEFAULT_LOCALE;
    isLoading = false;
    _error: IError | null = null;
    schoolId: number | null = null;
    classId: string | null = null;
    schoolYear: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setError(params: { message?: string; error?: unknown; resetError?: boolean }) {
        if (params.resetError) {
            this._error = null;
        } else if (params.message) {
            this._error = { message: params.message, error: params.error };
        }
    }

    get error() {
        return this._error;
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };
}

const generalStore = new GeneralStore();

export { generalStore };
