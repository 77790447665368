import localforage from "localforage";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import * as config from "../config";
import { APIError } from "../errors/APIError";
import { API, STATUS_CODE_UNAUTHORIZED } from "../network/API";
import { GetUserInfoResponse } from "../network/APITypes";

export interface ICredentials {
    accessToken: string;
    roleId: number;
}

export interface IProfile {
    uid: string;
    scope: string[];
    email: string;
}

type IUser = {
    firstName: string;
    lastName: string;
    username: string;
    image: string;
};

export type AuthError = "PasswordWrong" | "Unknown";

class Auth {
    credentials: ICredentials | null = null;
    userProfile: IProfile | null = null;
    userInfo: GetUserInfoResponse | null = null;
    firstName = "";
    lastName = "";
    user: IUser | null = null;
    error: AuthError | null = null;
    isAuthenticated = false;
    isLoading = false;
    globalLegalUpdatedAt: string | null = null;
    isRehydrated = false;

    constructor() {
        makeAutoObservable(this);
        this.initPersistence();
    }

    get isTeacher() {
        return this.credentials?.roleId === 5;
    }

    get isSchooladmin() {
        return this.credentials?.roleId === 3;
    }

    get isSuperadmin() {
        return this.credentials?.roleId === 1;
    }

    initPersistence = async () => {
        try {
            await makePersistable(this, {
                name: "auth",
                properties: ["credentials", "userProfile", "firstName", "lastName"],
                storage: localforage,
            });

            if (this.credentials !== null) {
                console.log("hydrate.auth: credentials are available, awaiting new token...");
                if (this.credentials.accessToken) {
                    this.isAuthenticated = true;
                }
            } else {
                console.log("rehydrated, no credentials are available.");
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.isRehydrated = true;
            });
        }
    };

    loginWithPasswordTeacher = async (firstName: string, lastName: string, password: string) => {
        if (this.isLoading) {
            // bailout, noop
            return;
        }

        this.isLoading = true;

        try {
            const credentials = await API.loginWithPasswordTeacher({
                firstName,
                lastName,
                password,
            });

            runInAction(() => {
                this.error = null;
                this.firstName = firstName;
                this.isLoading = false;
                this.credentials = credentials;

                // This has to be last! Because setting isAuthenticated to true triggers the <PublicRoute> component
                // to start redirecting in which case the credentials must be valid.
                this.isAuthenticated = true;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            });

            if (error instanceof APIError) {
                if (error.statusCode === STATUS_CODE_UNAUTHORIZED) {
                    this.wipe("PasswordWrong");
                }
            } else {
                this.wipe("Unknown");
            }
        }
    };

    logout = () => {
        this.wipe(null);
    };

    private wipe(error: AuthError | null) {
        this.credentials = null;
        this.error = error;
        this.isAuthenticated = false;
        this.isLoading = false;
        this.userProfile = null;
    }
}

let authStore: Auth;
if (process.env.NODE_ENV === "test") {
    class MockAuth {
        credentials: any = null;
        isAuthenticated = false;
        error: any = null;
        isRehydrated = true;

        constructor() {
            makeAutoObservable(this);
        }

        loginWithPassword = () => undefined;
        dismissError = () => undefined;
        logout = () => undefined;
    }

    authStore = new MockAuth() as any; // no localstorage support in node env
} else {
    authStore = new Auth();
}

// development, make auth available on window object...
(window as any).auth = authStore;

// singleton, exposes an instance by default
export { authStore };
