import * as React from "react";
import { Route } from "react-router-dom";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { Routes } from "../../app/router/Routes";
import { SuperadminSite } from "../sites/SuperadminSite";

export const SuperadminRouter = () => (
    <CustomSwitch>
        <Route exact path={Routes.SUPERADMIN.ROOT}>
            <SuperadminSite />
        </Route>
    </CustomSwitch>
);
