import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Button, Link, styled } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { API } from "../../../network/API";
import { GetClassesResponse, GetSchoolResponse } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { Colors } from "../../util/Colors";
import { ClassesTable } from "../components/ClassesTable";

const RADAR_CHART_ID = "chartdiv";

const SiteContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    padding: 48,
});

const StyledLink = styled(Link)({
    cursor: "pointer",
    color: "black",
    textDecorationColor: "black",
    "&: hover": {
        color: Colors.PRIMARY,
        textDecorationColor: Colors.PRIMARY,
    },
});

const SchoolInfoContainer = styled("div")({
    marginBottom: 24,
});

const disposeChart = (id: string) => {
    am5.array.each(am5.registry.rootElements, (root) => {
        if (root.dom.id === id) {
            root.dispose();
        }
    });
};

const createChart = (id: string) => {
    disposeChart(id);

    const root = am5.Root.new(RADAR_CHART_ID);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            startAngle: -84,
            endAngle: 264,
            innerRadius: am5.percent(40),
        }),
    );

    const cursor = chart.set(
        "cursor",
        am5radar.RadarCursor.new(root, {
            behavior: "zoomX",
        }),
    );
    cursor.lineY.set("forceHidden", true);

    const xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 30,
    });

    xRenderer.grid.template.set("forceHidden", true);

    const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: "category",
            renderer: xRenderer,
        }),
    );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {});
    yRenderer.labels.template.set("centerX", am5.p50);

    const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            min: 0,
            renderer: yRenderer,
        }),
    );

    const series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
            name: "Series 1",
            sequencedInterpolation: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
        }),
    );

    // Rounded corners for columns
    series.columns.template.setAll({
        cornerRadius: 5,
        tooltipText: "{categoryX}: {valueY}",
    });

    // Make each column to be of a different color
    series.columns.template.adapters.add("fill", function (fill, target) {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    const data = ["A,B,C", "D,E,F", "G,H,I", "J,K,L", "M,N,O", "P,Q,R", "S,T,V", "W,X,Y", "Z,Ö,Ä"].map(
        (letters, index) => ({
            category: letters,
            value: Math.round(Math.random() * 8),
        }),
    );

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);
};

export const SchoolSite = observer(() => {
    const [school, setSchool] = React.useState<GetSchoolResponse | null>(null);
    const [classes, setClasses] = React.useState<GetClassesResponse | null>(null);
    const { schoolId } = useParams<{ schoolId?: string }>();

    const isSuperadmin = authStore.isSuperadmin;
    const isSchooladmin = authStore.isSchooladmin;

    React.useEffect(() => {
        const loadSchool = async () => {
            if (schoolId) {
                const schoolResponse = await API.getSchool({ schoolId });
                setSchool(schoolResponse);
            }
        };

        const loadClasses = async () => {
            if (schoolId) {
                const classesResponse = await API.getClasses({ schoolId });
                if (authStore.isTeacher && authStore.userInfo?.teacherId) {
                    const newClasses = classesResponse.filter(
                        (classItem) => classItem.teacherId === authStore.userInfo?.teacherId,
                    );
                    setClasses(newClasses);
                } else {
                    setClasses(classesResponse);
                }
            }
        };

        generalStore.isLoading = true;
        try {
            loadSchool();
            loadClasses();
        } catch (error) {
            generalStore.setError({ message: "Beim Laden der Schuldaten ist ein Fehler aufgetreten", error });
        } finally {
            generalStore.isLoading = false;
        }
    }, [schoolId]);

    React.useEffect(() => {
        createChart(RADAR_CHART_ID);
    }, []);

    const handleClickManageSchooladmins = () => {
        // TODO
    };

    const handleClickManageTeachers = () => {
        // TODO
    };

    const handleClickExpandTable = (name: string) => {
        //
    };

    return (
        <SiteContainer>
            <SchoolInfoContainer>Name: {school?.name}</SchoolInfoContainer>
            {(isSuperadmin || isSchooladmin) && (
                <div style={{ marginBottom: 24 }}>
                    <Button
                        style={{ height: 60, marginRight: isSuperadmin ? 12 : 0 }}
                        variant="contained"
                        onClick={handleClickManageTeachers}
                    >
                        Lehrer verwalten
                    </Button>
                    {isSuperadmin && (
                        <Button
                            style={{ height: 60, wordBreak: "break-all" }}
                            variant="contained"
                            onClick={handleClickManageSchooladmins}
                        >
                            Schuladministratoren <br /> verwalten
                        </Button>
                    )}
                </div>
            )}
            <div>
                <div style={{ fontSize: 22, fontWeight: 600 }}>Klassen</div>
            </div>
            {classes?.map((classItem) => (
                <React.Fragment key={classItem.classId}>
                    {/* <ClassesTable classes={classes} handleClick={handleClickExpandTable} /> */}
                    <div>{classItem.className}</div>
                </React.Fragment>
            ))}

            <div id={RADAR_CHART_ID} style={{ width: "100%", height: "500px" }} />
        </SiteContainer>
    );
});

//const initialClasses: IClass[] = times(2, () => mockClass);
//const [classes, setClasses] = React.useState(initialClasses.map((classItem) => ({ ...classItem, isOpen: false })));
// TODO: remove, just for testing purposes
// const [classes, setClasses] = React.useState<IClassNew[]>([]);
/* const handleClickExpandClass = (name: string) => {
        setClasses(
            classes.map((classItem) =>
                classItem.name === name ? { ...classItem, isOpen: !classItem.isOpen } : classItem,
            ),
        );
    }; */
