import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import * as React from "react";
import { API } from "../../../network/API";
import { GetSchoolsResponse } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { Colors } from "../../util/Colors";

const SiteContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    padding: 50,
});

const StyledButton = styled(Button)({
    height: 48,
});

const SectionContainer = styled("div")({
    flexGrow: 1,
});

const SchoolsGrid = styled("div")({
    display: "grid",
    gridTemplateColumns: "200px 1fr 1fr 1fr 200px",
});

const SchoolContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 48,
});

const SchoolImageContainer = styled("div")({
    textAlign: "center",
    cursor: "pointer",
});

const getSchoolColumn = (index: number) => {
    if (index % 3 === 0) {
        return 2;
    }
    if (index % 3 === 1) {
        return 3;
    }
    return 4;
};

const EmptyStateInfo = styled("div")({
    fontSize: 26,
    fontWeight: 400,
    textAlign: "center",
});

const BackButton = ({ onClick, style }: { onClick: () => void; style?: React.CSSProperties }) => {
    return (
        <IconButton style={style} onClick={onClick}>
            <ArrowBackIosNewIcon />
        </IconButton>
    );
};

export const SuperadminSite = observer(() => {
    const [schools, setSchools] = React.useState<GetSchoolsResponse | undefined>(undefined);
    const [manageSchools, setManageSchools] = React.useState(false);
    const [manageTasks, setManageTasks] = React.useState(false);

    const loadSchools = React.useCallback(async () => {
        generalStore.isLoading = true;
        try {
            const schoolsResponse = await API.getSchools();
            setSchools(schoolsResponse);
        } catch (error) {
            generalStore.setError({ message: "Beim Laden der Schuldaten ist ein Fehler aufgetreten", error });
        } finally {
            generalStore.isLoading = false;
        }
    }, []);

    React.useEffect(() => {
        if (manageSchools) {
            loadSchools();
        }
    }, [loadSchools, manageSchools]);

    const handleClickManageSchools = () => {
        setManageSchools(true);
    };

    const handleClickManageTasks = () => {
        setManageTasks(true);
    };

    const handleClickBack = () => {
        setManageSchools(false);
        setManageTasks(false);
    };

    const handleClickSchool = (id: number) => {
        pushRoute(Routes.SCHOOL.ROOT.replace(":schoolId", `${id}`));
    };

    const handleClickAddSchool = () => {
        // TODO
    };

    return (
        <>
            <SiteContainer>
                {!manageSchools && !manageTasks && (
                    <div>
                        <StyledButton
                            style={{ marginRight: 12 }}
                            variant="contained"
                            onClick={handleClickManageSchools}
                        >
                            Schulen verwalten
                        </StyledButton>
                        <StyledButton variant="contained" onClick={handleClickManageTasks}>
                            Aufgaben verwalten
                        </StyledButton>
                    </div>
                )}
                {manageSchools && !generalStore.isLoading && (
                    <SectionContainer>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <BackButton style={{ marginBottom: 12 }} onClick={handleClickBack} />
                            <Button variant="outlined" onClick={handleClickAddSchool}>
                                Schule hinzufügen
                            </Button>
                        </div>
                        {schools ? (
                            <SchoolsGrid>
                                {schools.map((school, index) => {
                                    return (
                                        <SchoolContainer key={school.id} style={{ gridColumn: getSchoolColumn(index) }}>
                                            <SchoolImageContainer
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleClickSchool(school.id)}
                                            >
                                                <img height={256} width={256} src={school.img} alt="school" />
                                                <div>{school.name}</div>
                                            </SchoolImageContainer>
                                        </SchoolContainer>
                                    );
                                })}
                            </SchoolsGrid>
                        ) : (
                            <EmptyStateInfo>Sie haben noch keine Schulen hinzugefügt</EmptyStateInfo>
                        )}
                    </SectionContainer>
                )}
                {manageTasks && (
                    <SectionContainer>
                        <BackButton onClick={handleClickBack} />
                        {/* TODO */}
                    </SectionContainer>
                )}
            </SiteContainer>
        </>
    );
});
