import { observer } from "mobx-react";
import * as React from "react";
import { Route, Router } from "react-router-dom";
import { generalStore } from "../../../stores/GeneralStore";
import { SchoolContainerSite } from "../../school/sites/SchoolContainerSite";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { AuthLoginSite } from "../../auth/sites/AuthLoginSite";
import { CustomSwitch } from "./CustomSwitch";
import { history } from "./history";
import { NoAuthOnlyRoute } from "./NoAuthOnlyRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Routes } from "./Routes";
import { RoutingManager } from "./RoutingManager";
import { Navbar } from "../../ui/Navbar";
import { SuperadminContainerSite } from "../../superadmin/sites/SuperadminContainerSite";

export const AppRouter = observer(() => (
    <>
        <Router history={history}>
            <RoutingManager>
                <CustomSwitch>
                    <NoAuthOnlyRoute exact path={Routes.ROOT}>
                        <AuthLoginSite />
                    </NoAuthOnlyRoute>
                    <Route>
                        <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
                            <Navbar />
                            <PrivateRoute exact path={Routes.SUPERADMIN.ROOT}>
                                <SuperadminContainerSite />
                            </PrivateRoute>
                            <PrivateRoute exact path={Routes.SCHOOL.ROOT}>
                                <SchoolContainerSite />
                            </PrivateRoute>
                        </div>
                    </Route>
                </CustomSwitch>
            </RoutingManager>
        </Router>
        {generalStore.isLoading && <LoadingOverlay />}
    </>
));
